/* my-share-button.css */
.mastoshare-button {
    display: inline-block;
    font: bold 11px Arial;
    color: #ffffff;
    background-color: #6364ff;
    text-decoration: none;
    padding: 8px 16px;
    border-radius: 5px;
}

.mastoshare-button:hover {
    background-color: #563acc;
}
